import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../../styles/variables";

const WrapperDiv = styled.div`
  padding: 0 ${spacers.wrapper.mobile}px;
  width: 100%;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 0;
  }
`;

const Wrapper: React.FC = ({ children }) => <WrapperDiv>{children}</WrapperDiv>;

export default Wrapper;
