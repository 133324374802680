import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

interface TextProps {
  center?: boolean;
  text: string;
  marginBottom?: boolean;
  centerMobile?: boolean;
}

const TextArt = styled.article`
  font: 14px / 28px "RC Regular";
  letter-spacing: 1.4px;
  margin-bottom: 20px;
  text-align: ${(props) => (props.centerMobile ? "center" : "left")};

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-left: 20px;
    margin-bottom: 20px;

    p {
      margin-bottom: 0;
    }
  }

  a {
    color: ${colors.orange};
    text-decoration: none;
  }

  strong,
  b {
    font-family: "RC Bold";
  }

  &.center {
    text-align: center;
  }

  &.marginBottom {
    margin-bottom: 55px;
  }

  *.orange-text  {
    color: ${colors.orange};
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 20px / 50px "RC Regular";
    letter-spacing: 2px;
    margin-bottom: 40px;
    text-align: ${(props) => (props.centerMobile ? "left" : "left")};

    &.marginBottom {
      margin-bottom: 120px;
    }
  }
`;

const Text: React.FC<TextProps> = ({
  center,
  text,
  marginBottom,
  centerMobile,
}) => (
  <TextArt
    className={
      center && marginBottom
        ? "center marginBottom"
        : center
        ? "center"
        : marginBottom
        ? "marginBottom"
        : ""
    }
    centerMobile={centerMobile}
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

export default Text;
