import * as React from "react";
import DefaultLayout from "../layouts/default";
import Text from "../components/Text";
import TextWrapper from "../components/Layouting/TextWrapper";
import Title from "../components/Title";

const FourOFour: React.FC = () => {
  return (
    <DefaultLayout beschreibung="404: Seite nicht gefunden" titel="404">
      <Title text="404" topOffset />
      <TextWrapper>
        <Text text="<p>Seite nicht gefunden</p>" center />
      </TextWrapper>
    </DefaultLayout>
  );
};

export default FourOFour;
