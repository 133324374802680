import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

interface TitleProps {
  mobile?: boolean;
  text: string;
  topOffset?: boolean;
}

const TitleText = styled.h1`
  display: block;
  font: 28px / 40px "RC Light";
  letter-spacing: 3.5px;
  margin-bottom: 18px;
  color: #000;
  width: 100%;
  text-align: center;

  &.topOffset {
    margin-top: 90px;
  }

  strong,
  b {
    font-family: "RC Bold";
    color: ${colors.orange};
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    bottom: 54px;
    font: 64px / 75px "RC Light";
    letter-spacing: 8px;

    &.topOffset {
      margin-top: 160px;
    }

    &.mobile {
      display: none;
    }
  }
`;

const Title: React.FC<TitleProps> = ({ mobile, text, topOffset }) => (
  <TitleText
    className={
      mobile && topOffset
        ? "mobile topOffset"
        : mobile
        ? "mobile"
        : topOffset
        ? "topOffset"
        : ""
    }
    // dangerouslySetInnerHTML={{ __html: text }}
  >
    <p>
      Menu Bistro-<b>Z</b>
    </p>
  </TitleText>
);

export default Title;
